import { useFetch } from 'lib/fetch';
import { redirect } from 'lib/location';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export const withContractors = (WrappedComponent) => {
  return (props) => {
    const { body, className, data, isEditMode } = props;
    const contractId = body?.contract || data?.contract?._id;
    const { fetchContractors, contractors } = useFetchContractors({ contractId });

    const readonly = isEditMode && data?.record_status > 10;

    useEffect(() => {
      if (contractId) {
        fetchContractors();
      }
    }, [contractId]);

    const options = [{ _id: '', label: '-' }, ...contractors];

    if (isEmpty(contractors)) {
      return null;
    }

    return (
      <WrappedComponent
        {...props}
        options={options}
        contractId={contractId}
        readonly={readonly}
        length={contractors.length}
      />
    );
  };
};

export const useFetchContractors = (props) => {
  const { contractId } = props;
  const { fetch, result } = useFetch();

  const fetchContractors = () => {
    fetch('GET', `/api/collection/contract/external/get-contract-contractors/${contractId}`);
  };

  return {
    fetchContractors,
    contractors: result || []
  };
};
