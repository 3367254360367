import React from 'react';
import { IconButton } from '@mui/material';
import { withSubcontractor } from './hooks';
import { Close } from '@mui/icons-material';
import InputEdit from 'components/common/input-edit';
import { Divider } from 'antd';
import { Select, MenuItem } from '@mui/material';

function SubContractor(props) {
  const {
    clear,
    options = [],
    order,
    setForm,
    subcontractor,
    percentage,
    contact_email,
    contact_person,
    contact_phone,
    contactOptions,
    setContact,
    contactSelectOptions,
    showClearButton,
    isContactRequired,
    isContactReadonly,
    readonly
  } = props;
  return (
    <div className="flex gap-2">
      <div className="text-base">{order + 1}</div>
      <div className="flex flex-col w-full gap-1">
        <div className="flex w-full gap-4">
          <div className="basis-1/3 flex gap-4 items-center">
            <InputEdit
              className="w-full"
              setInputs={setForm}
              value={subcontractor?.value}
              field="subcontractor"
              schema={{
                title: '判頭',
                field: 'subcontractor',
                input_type: 'select',
                selectOptions: options,
                forceUpdate: true,
                readonly
              }}
            />
          </div>
          <div className="basis-1/3">
            <InputEdit
              className="w-full"
              setInputs={setForm}
              value={percentage?.value}
              field="percentage"
              schema={{
                title: '佔比',
                field: 'percentage',
                type: 'text',
                is_percentage: true,
                forceUpdate: true,
                editable: !isContactReadonly,
                readonly
              }}
            />
          </div>
          <div className="basis-1/3 flex items-center">
            {showClearButton && (
              <div className="-ml-2 mt-6">
                <IconButton aria-label="delete" size="small" onClick={clear}>
                  <Close fontSize="inherit" />
                </IconButton>
              </div>
            )}
          </div>
        </div>

        {subcontractor?.value &&
          !contact_person?.value &&
          !contact_phone?.value &&
          !contact_email?.value &&
          contactSelectOptions.length > 0 && (
            <div className="flex w-full gap-4">
              <div className="w-full basis-1/3">
                <div className="mb-1">選擇聯絡人</div>
                <Select
                  size="small"
                  value={''}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                >
                  {contactSelectOptions?.map((options) => {
                    return (
                      <MenuItem value={options?._id} key={options?.person}>
                        {options?.person}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="basis-1/3" />
              <div className="basis-1/3" />
            </div>
          )}

        <div className="flex gap-4">
          <InputEdit
            className="w-1/3"
            setInputs={setForm}
            value={contact_person?.value}
            field="contact_person"
            schema={{
              title: '聯絡人',
              field: 'contact_person',
              // input_type: 'select',
              // selectOptions: contactOptions?.persons || [],
              // freesolo: true,
              forceUpdate: true,
              is_required: isContactRequired,
              editable: !isContactReadonly,
              readonly
            }}
          />
          <InputEdit
            className="w-1/3"
            setInputs={setForm}
            value={contact_phone?.value}
            field="contact_phone"
            schema={{
              title: '聯絡人電話',
              field: 'contact_phone',
              // input_type: 'select',
              // selectOptions: contactOptions?.phones || [],
              // freesolo: true,
              forceUpdate: true,
              is_required: isContactRequired,
              is_phone: true,
              editable: !isContactReadonly,
              readonly
            }}
          />
          <InputEdit
            className="w-1/3"
            setInputs={setForm}
            value={contact_email?.value}
            field="contact_email"
            schema={{
              title: '聯絡人電郵',
              field: 'contact_email',
              // input_type: 'select',
              // selectOptions: contactOptions?.emails || [],
              // freesolo: true,
              forceUpdate: true,
              is_required: isContactRequired,
              is_email: true,
              editable: !isContactReadonly,
              readonly
            }}
          />
        </div>
        <Divider className="my-1" />
      </div>
    </div>
  );
}

export default withSubcontractor(SubContractor);
