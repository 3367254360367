import { useFetch } from 'lib/fetch';
import { find } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

const tiers = ['A1', 'A2', 'A3', 'A4', 'Contractor', 'A5', 'A6', 'A7'];

export const withApprovalStatus = (WrappedComponent) => {
  return (props) => {
    const { historyData = [], prUserData = [], data } = props;
    const currentUser = useAtomValue(user);

    const rows = useMemo(() => {
      return tiers.map((tier) => {
        if (tier === 'Contractor') {
          const is_approve = data?.subcontractor_verified === true;
          const skip = data?.skip_subcontractor;
          return {
            tier,
            active: skip || is_approve,
            is_approve,
            skip
          };
        }
        const record = find(historyData, { tier });
        const skip = data?.[`skip_${tier}`];
        const users = prUserData
          ?.filter((row) => row?.tier === tier && row?.level >= 10)
          .map((user) => ({
            ...user,
            active: user?.user?._id === record?.user?._id,
            is_approve: record?.is_approve,
            isMyself: currentUser?._id === user?.user?._id
          }))
          .sort((user) => (user.active ? -1 : 1));
        return {
          ...record,
          tier,
          active: !!record?.user || skip,
          users,
          skip
        };
      });
    }, [data, prUserData, historyData, currentUser?._id]);
    return <WrappedComponent {...props} rows={rows} />;
  };
};
