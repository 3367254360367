import { every, find, isBoolean, isEmpty, isUndefined, map, reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { user } from 'store';

export const withSubcontractorReferences = (WrappedComponent) => {
  return (props) => {
    const { data, setInputs, inputs, userProfile } = props;

    const currentUser = useAtomValue(user);

    const { _id, record_status, created_by, pr__subcontractors, skip_subcontractor, subcontractor_verified } =
      data || {};

    const readonly = useMemo(() => {
      if (skip_subcontractor || isBoolean(subcontractor_verified)) {
        return true;
      }
      if (created_by?._id === currentUser?._id && record_status > 40) {
        // if (record_status && record_status < 40) {
        //   return true;
        // }

        return false;
      }

      return false;
    }, [record_status, currentUser, created_by, skip_subcontractor, subcontractor_verified]);

    const showApproveButton = useMemo(() => {
      const filledIn = every(map(pr__subcontractors, 'references'), (e) => !isEmpty(e));
      return !readonly && filledIn && !isEmpty(pr__subcontractors);
    }, [readonly, pr__subcontractors]);

    const schema = useMemo(() => {
      return {
        readonly,
        is_file: true,
        is_multiple: true
      };
    }, [readonly]);

    return (
      <WrappedComponent
        {...props}
        _id={_id}
        schema={schema}
        setInputs={setInputs}
        readonly={readonly}
        rows={pr__subcontractors}
        skip={false}
        showApproveButton={showApproveButton}
      />
    );
  };
};
